import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71e16832"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "card-px text-center py-20 my-10" }
const _hoisted_4 = { class: "fs-2x fw-bolder mb-10" }
const _hoisted_5 = { class: "text-gray-400 fs-5 fw-bold mb-13" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "tab-content rounded h-100 bg-light p-10" }
const _hoisted_8 = { class: "plan-features" }
const _hoisted_9 = { class: "pb-5" }
const _hoisted_10 = { class: "fw-bolder text-dark" }
const _hoisted_11 = { class: "text-gray-400 fw-bold" }
const _hoisted_12 = { class: "pt-1" }
const _hoisted_13 = { class: "fw-bold fs-5 text-gray-700 flex-grow-1" }
const _hoisted_14 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_15 = { class: "fw-bold fs-5 text-gray-400 flex-grow-1" }
const _hoisted_16 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "card plan-card"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(plan.name), 1),
            _createElementVNode("p", _hoisted_5, [
              _createElementVNode("span", {
                innerHTML: plan.detail
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h2", _hoisted_10, " What’s in " + _toDisplayString(plan.name) + "? ", 1),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(plan.detail) + " - $" + _toDisplayString(plan.price), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plan.features, (feature, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: i,
                      class: _normalizeClass([[i !== plan.features.length - 1 && 'mb-7'], "d-flex align-items-center"])
                    }, [
                      feature
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("span", _hoisted_13, _toDisplayString(feature.name), 1),
                            _createElementVNode("span", _hoisted_14, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen043.svg" })
                            ])
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(feature.name), 1),
                            _createElementVNode("span", _hoisted_16, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen040.svg" })
                            ])
                          ], 64))
                    ], 2))
                  }), 128))
                ])
              ])
            ]),
            _createVNode(_component_router_link, {
              to: { name: 'customer-addemployee' },
              class: "btn btn-primary er fs-6 px-8 py-4 m-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Order Report")
              ]),
              _: 1
            })
          ])
        ])
      ]))
    }), 128))
  ]))
}